<template>
  <engaging-stand-out-block
    v-if="userIsLoggedInState && hasSurname"
    :title="engagingBlockTitleHasSurname"
    button-label="Go to My Tree"
    @click="goToMyTree"
  ></engaging-stand-out-block>
  <engaging-stand-out-block
    v-else-if="userIsLoggedInState && !hasSurname"
    title="Is this your surname?"
    button-label="Yes, Save to My Tree"
    :button-loading="familyTreePersonUpdateLoadingState"
    @click="saveToMyTree"
  ></engaging-stand-out-block>
</template>

<script>
import EngagingStandOutBlock from '@common/elements/layouts/EngagingStandOutBlock';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getFamilyTreeRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

export default {
  props: {
    surname: String,
    surnamesToSave: Array,
    hasSurname: Boolean,
  },
  computed: {
    ...mapGetters(['userIsLoggedInState', 'familyTreePersonState', 'familyTreePersonUpdateLoadingState']),
    engagingBlockTitleHasSurname() {
      return `Add ${this.surname} ancestors to your tree.`;
    },
  },
  methods: {
    goToMyTree() {
      AnalyticsAmplitudeHandler.trackSurnameGoToTreeClick(this.$route.query.word);
      this.$router.push(getFamilyTreeRoute(this.$store));
    },
    saveToMyTree() {
      const payload = {
        ...this.familyTreePersonState,
        id: 'my',
        surnames: [...this.familyTreePersonState.surnames, ...this.surnamesToSave.map(value => ({value, type: ''}))],
      };
      AnalyticsAmplitudeHandler.trackSurnameSaveToTreeClick(this.$route.query.word);
      this.$store.dispatch('updateFamilyTreePersonAction', payload).then(() => {
        this.$router.push({name: 'get-started'});
      });
    },
  },
  components: {EngagingStandOutBlock},
};
</script>

<style scoped></style>
